import { throwRepoErrorFromApiV3Error } from '@webapp/core/utils/repositories/throwRepoErrorFromApiV3Error'
import { type RepoStandardResponse } from '@webapp/infrastructure/interfaces/repositories/RepoStandardResponse'
import { type IHttpClient } from '@webapp/infrastructure/interfaces/services/IHttpClient'
import { type IBudgetRepository } from '../../../business-logic/interfaces/IBudgetRepository'
import { type LegacyBudgetConstructorData } from '../../../entities/LegacyBudget'
import { type LegacyBudgetOverviewConstructorData } from '../../../entities/LegacyBudgetOverview'
import { mapLegacyBudgetApiModel } from '../../mappers/mapLegacyBudgetApiModel'
import { mapLegacyBudgetOverviewApiModel } from '../../mappers/mapLegacyBudgetOverviewApiModel'
import { type GetLegacyBudgetOverviewResponse } from '../../types/GetLegacyBudgetOverviewResponse'
import { type GetLegacyBudgetsResponse } from '../../types/GetLegacyBudgetsResponse'

interface IDependency {
  httpClient: IHttpClient
}

export class BudgetRepository implements IBudgetRepository {
  constructor(private readonly deps: IDependency) {}

  async getLegacyBudgets(
    accountCodeIds: number[],
    secondaryCategoryIds: number[]
  ): Promise<RepoStandardResponse<LegacyBudgetConstructorData[]>> {
    try {
      const {
        data: { data },
      } = await this.deps.httpClient.get<GetLegacyBudgetsResponse>(
        '/api/v3/budgets/',
        {
          params: {
            active: true,
            department: secondaryCategoryIds.join(','),
            account_code: accountCodeIds.join(','),
          },
        }
      )

      return {
        data: data.map(mapLegacyBudgetApiModel),
      }
    } catch (err) {
      throw throwRepoErrorFromApiV3Error(err)
    }
  }

  async getLegacyBudgetOverview(
    secondaryCategoryId: number
  ): Promise<RepoStandardResponse<LegacyBudgetOverviewConstructorData | null>> {
    try {
      const {
        data: { data },
      } = await this.deps.httpClient.get<GetLegacyBudgetOverviewResponse>(
        '/api/v3/budgets/overview/',
        {
          params: {
            active_budgets: true,
            department: secondaryCategoryId,
          },
        }
      )

      return {
        data: data.length ? mapLegacyBudgetOverviewApiModel(data[0]) : null,
      }
    } catch (err) {
      throw throwRepoErrorFromApiV3Error(err)
    }
  }
}
