import { formatRepoFiltersToApiFilters } from '@webapp/core/utils/repositories/formatRepoFiltersToApiFilters'
import { throwRepoErrorFromApiV3Error } from '@webapp/core/utils/repositories/throwRepoErrorFromApiV3Error'
import { type RepoFilterParams } from '@webapp/infrastructure/interfaces/repositories/RepoFilterParams'
import { type RepoPaginatedResponse } from '@webapp/infrastructure/interfaces/repositories/RepoPaginatedResponse'
import { type RepoStandardResponse } from '@webapp/infrastructure/interfaces/repositories/RepoStandardResponse'
import { type IHttpClient } from '@webapp/infrastructure/interfaces/services/IHttpClient'
import { type IUserRepository } from '@webapp/platform/iam/business-logic/interfaces/IUserRepository'
import { mapDetailedUserApiModel } from '@webapp/platform/iam/data-access/mapper/mapDetailedUserApiModel'
import { mapUserSettingApiModel } from '@webapp/platform/iam/data-access/mapper/mapUserSettingApiModel'
import { type EditUserDetailsPayload } from '@webapp/platform/iam/data-access/types/editUserDetailsPayload'
import { type GetUserDetailsResponse } from '@webapp/platform/iam/data-access/types/getUserDetailsResponse'
import { type GetUserSettingResponse } from '@webapp/platform/iam/data-access/types/getUserSettingResponse'
import { type UpdateProfileImagePayload } from '@webapp/platform/iam/data-access/types/updateProfileImagePayload'
import { type UpdatePurchasePriceLimitPayload } from '@webapp/platform/iam/data-access/types/updatePurchasePriceLimitPayload'
import { type DetailedUserConstructor } from '@webapp/platform/iam/entities/DetailedUser'
import { type UserConstructor } from '@webapp/platform/iam/entities/User'
import { type UserPurchasePriceLimitConstructor } from '@webapp/platform/iam/entities/UserPurchasePriceLimit'
import { mapUserApiModel } from '../../mapper/mapUserApiModel'
import { type InviteUserPayload } from '../../types/createUserPayload'
import { type GetUserListResponse } from '../../types/getUserListResponse'

interface Dependency {
  httpClient: IHttpClient
}

export class UserRepository implements IUserRepository {
  constructor(private readonly deps: Dependency) {}

  async list(
    isActive?: boolean,
    params?: RepoFilterParams
  ): Promise<RepoPaginatedResponse<UserConstructor>> {
    const res = await this.deps.httpClient.get<GetUserListResponse>(
      `/api/v3/users/`,
      {
        params: {
          is_active: isActive,
          ...formatRepoFiltersToApiFilters(params),
        },
      }
    )

    const users = res.data.data
    const metadata = res.data.metadata

    return {
      data: users.map((user) => mapUserApiModel(user)),
      pagination: {
        count: metadata?.pagination?.count || 0,
        page: metadata?.pagination?.current_page || 0,
        pageSize: metadata?.pagination?.page_size || 0,
        numPages: metadata?.pagination?.num_pages || 0,
      },
    }
  }

  async getDetails(
    userId: number
  ): Promise<RepoStandardResponse<DetailedUserConstructor>> {
    const userResponse = await this.deps.httpClient.get<GetUserDetailsResponse>(
      `/api/v3/users/${userId}/`,
      {
        params: {
          format: 'json',
        },
      }
    )
    const { data: detailedUser } = userResponse.data

    return {
      data: mapDetailedUserApiModel(detailedUser),
    }
  }

  async editDetails(
    userId: number,
    payload: EditUserDetailsPayload
  ): Promise<void> {
    await this.deps.httpClient.put(`/api/v3/users/${userId}/`, payload)
  }

  async getPurchasePriceLimit(
    userId: number
  ): Promise<RepoStandardResponse<UserPurchasePriceLimitConstructor>> {
    const userThresholdSettingsResponse =
      await this.deps.httpClient.get<GetUserSettingResponse>(
        `/api/v2/user/${userId}/setting/`
      )

    const { data: userThresholdSettings } = userThresholdSettingsResponse.data

    return {
      data: mapUserSettingApiModel(userThresholdSettings),
    }
  }

  async updatePurchasePriceLimit(
    userId: number,
    payload: UpdatePurchasePriceLimitPayload
  ): Promise<void> {
    await this.deps.httpClient.put(`/api/v2/user/${userId}/setting/`, payload)
  }

  async resetUserMFA(userId?: number): Promise<void> {
    if (!userId) {
      throw new Error('Missing userId')
    }

    await this.deps.httpClient.delete(
      `/api/v3/users/${userId.toString()}/authentication-methods`
    )
  }

  async inviteUser(data: InviteUserPayload[]): Promise<void> {
    try {
      await this.deps.httpClient.post('/api/v3/users/', data)
    } catch (err) {
      throw throwRepoErrorFromApiV3Error(err)
    }
  }

  async delete(userId: number): Promise<void> {
    await this.deps.httpClient.delete(`/api/v3/users/${userId}/`)
  }

  async activate(userId: number): Promise<void> {
    await this.deps.httpClient.put(`/api/v3/users/${userId}/reactivate/`, {})
  }

  async updateProfileImage(
    userId: number,
    payload: UpdateProfileImagePayload
  ): Promise<RepoStandardResponse<UserConstructor>> {
    const userResponse =
      await this.deps.httpClient.patch<GetUserDetailsResponse>(
        `/api/v3/users/${userId}/`,
        payload
      )

    const { data: user } = userResponse.data

    return {
      data: mapUserApiModel(user),
    }
  }
}
