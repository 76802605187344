import { type SecondaryCategoryConstructor } from '@webapp/core/entities/internal/SecondaryCategory'
import { mapLocation } from './mapLocation'
import { type DepartmentApiModel } from '../api-models/DepartmentApiModel'

export const mapDepartment = (
  dept: Pick<DepartmentApiModel, 'id' | 'name' | 'branch' | 'location'>
): SecondaryCategoryConstructor => ({
  id: dept.id,
  name: dept.name,
  primaryCategory: dept.branch
    ? mapLocation(dept.branch)
    : dept.location
      ? mapLocation(dept.location)
      : null,
})
