import { mapDepartment } from '@webapp/core/data-access/mappers/mapDepartment'
import { type LegacyBudgetConstructorData } from '../../entities/LegacyBudget'
import { type LegacyBudgetApiModel } from '../api-models/LegacyBudgetApiModel'

export const mapLegacyBudgetApiModel = (
  budget: LegacyBudgetApiModel
): LegacyBudgetConstructorData => ({
  id: budget.id,
  account: {
    accountCode: {
      id: budget.account.account_code.id,
      code: budget.account.account_code.code,
      description: budget.account.account_code.description,
    },
    id: budget.account.id,
  },
  isClosed: budget.is_closed,
  isExpired: budget.is_expired,
  amount: budget.budget,
  secondaryCategory: mapDepartment(budget.department),
  startDate: budget.startDate,
  endDate: budget.endDate,
  approvedSpend: budget.approved,
  invoicedSpend: budget.invoiced,
  pendingSpend: budget.pending,
  purchasedSpend: budget.purchased,
})
