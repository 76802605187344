import * as Factory from 'factory.ts'
import faker from 'faker'

import { type LocationApiModel } from '../LocationApiModel'

export const PrimaryCategoryApiModelStub =
  Factory.Sync.makeFactory<LocationApiModel>({
    id: Factory.each(() => faker.random.number()),
    name: Factory.each(() => faker.random.word()),
    headquarter: false,
  })
