import {
  SecondaryCategory,
  type SecondaryCategoryConstructor,
} from '@webapp/core/entities/internal/SecondaryCategory'

export interface LegacyBudgetOverviewConstructorData {
  amount: Nullable<string>
  secondaryCategory: SecondaryCategoryConstructor
  startDate: ISODateString
  endDate: ISODateString
  approvedSpend: string
  invoicedSpend: string
  pendingSpend: string
  purchasedSpend: string
}

export class LegacyBudgetOverview {
  amount: Nullable<string>
  secondaryCategory: SecondaryCategory
  spend: {
    approvedSpend: string
    invoicedSpend: string
    pendingSpend: string
    purchasedSpend: string
  }
  startDate: Date
  endDate: Date

  constructor(data: LegacyBudgetOverviewConstructorData) {
    this.amount = data.amount
    this.secondaryCategory = new SecondaryCategory(data.secondaryCategory)
    this.spend = {
      approvedSpend: data.approvedSpend,
      invoicedSpend: data.invoicedSpend,
      pendingSpend: data.pendingSpend,
      purchasedSpend: data.purchasedSpend,
    }
    this.startDate = new Date(data.startDate)
    this.endDate = new Date(data.endDate)
  }
}
