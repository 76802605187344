import * as Factory from 'factory.ts'
import faker from 'faker'

import { SecondaryCategoryConstructorFactory } from '@webapp/core/entities/internal/SecondaryCategory'
import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import {
  LegacyBudgetOverview,
  type LegacyBudgetOverviewConstructorData,
} from './LegacyBudgetOverview'

export const LegacyBudgetOverviewConstructorFactory =
  Factory.Sync.makeFactory<LegacyBudgetOverviewConstructorData>({
    amount: Factory.each(() => faker.random.number().toString()),
    secondaryCategory: Factory.each(() =>
      SecondaryCategoryConstructorFactory.build()
    ),
    startDate: Factory.each(() => faker.date.recent().toISOString()),
    endDate: Factory.each(() => faker.date.recent().toISOString()),
    approvedSpend: Factory.each(() => faker.random.number().toString()),
    invoicedSpend: Factory.each(() => faker.random.number().toString()),
    pendingSpend: Factory.each(() => faker.random.number().toString()),
    purchasedSpend: Factory.each(() => faker.random.number().toString()),
  })

export const LegacyBudgetOverviewFactory = EntityFactory(
  LegacyBudgetOverview,
  LegacyBudgetOverviewConstructorFactory
)
