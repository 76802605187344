import {
  ApproverDelegatee,
  type ApproverDelegateeConstructor,
} from '@webapp/core/entities/internal/ApproverDelegatee'
import {
  PrimaryCategory,
  type PrimaryCategoryConstructor,
} from '@webapp/core/entities/internal/PrimaryCategory'
import {
  SecondaryCategory,
  type SecondaryCategoryConstructor,
} from '@webapp/core/entities/internal/SecondaryCategory'
import { type Permission } from '@webapp/platform/iam/entities/Permission'
import { Permissions } from '../enums'
import { UserRole, type UserRoleConstructor } from '../UserRole'

export interface DetailedUserConstructor {
  id: number
  authId: number
  firstName: string
  lastName: string
  email: string
  phone: string
  primaryCategory: Nullable<PrimaryCategoryConstructor>
  secondaryCategory: Nullable<SecondaryCategoryConstructor>
  profileImage: string
  active: boolean
  position: string
  role: Nullable<UserRoleConstructor>
  permissions: {
    id: Permission['id']
    key: Permission['key']
    primaryCategoryIds: Nullable<number[]>
    secondaryCategoryIds: Nullable<number[]>
  }[]
  approvalDelegatee: Nullable<ApproverDelegateeConstructor>
}

export class DetailedUser {
  id: number
  authId: number
  firstName: string
  lastName: string
  email: string
  phone: string
  primaryCategory: Nullable<PrimaryCategory>
  secondaryCategory: Nullable<SecondaryCategory>
  profileImage: string
  active: boolean
  position: string
  role: Nullable<UserRole>
  organizationalCategorySpecificPermissions: {
    id: Permission['id']
    key: Permission['key']
    primaryCategoryIds: Nullable<number[]>
    secondaryCategoryIds: Nullable<number[]>
  }[]
  approvalDelegatee: Nullable<ApproverDelegatee>

  constructor(data: DetailedUserConstructor) {
    this.id = data.id
    this.authId = data.authId
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.phone = data.phone
    this.primaryCategory = data.primaryCategory
      ? new PrimaryCategory(data.primaryCategory)
      : null
    this.secondaryCategory = data.secondaryCategory
      ? new SecondaryCategory(data.secondaryCategory)
      : null
    this.profileImage = data.profileImage
    this.active = data.active
    this.position = data.position
    this.role = data.role ? new UserRole(data.role) : null
    this.organizationalCategorySpecificPermissions = data.permissions.map(
      (p) => ({
        id: p.id,
        key: p.key,
        primaryCategoryIds: p.primaryCategoryIds,
        secondaryCategoryIds: p.secondaryCategoryIds,
      })
    )
    this.approvalDelegatee = data.approvalDelegatee
      ? new ApproverDelegatee(data.approvalDelegatee)
      : null
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get isPurchaser(): boolean {
    return this.organizationalCategorySpecificPermissions.some(
      (p) => p.key === Permissions.PROCURE_ADD_PO
    )
  }
}
