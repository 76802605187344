import {
  SecondaryCategory,
  type SecondaryCategoryConstructor,
} from '@webapp/core/entities/internal/SecondaryCategory'
import { type AccountCode } from '@webapp/platform/finance'

export interface LegacyBudgetConstructorData {
  id: number
  account: {
    accountCode: Pick<AccountCode, 'id' | 'code' | 'description'>
    id: number
  }
  amount: string
  secondaryCategory: SecondaryCategoryConstructor
  startDate: ISODateString
  endDate: ISODateString
  approvedSpend: string
  invoicedSpend: string
  pendingSpend: string
  purchasedSpend: string
  isClosed: boolean
  isExpired: boolean
}

export class LegacyBudget {
  id: number
  account: {
    accountCode: Pick<AccountCode, 'id' | 'code' | 'description'>
    id: number
  }
  amount: string
  secondaryCategory: SecondaryCategory
  spend: {
    approvedSpend: string
    invoicedSpend: string
    pendingSpend: string
    purchasedSpend: string
  }
  startDate: Date
  endDate: Date
  isClosed: boolean
  isExpired: boolean

  constructor(data: LegacyBudgetConstructorData) {
    this.id = data.id
    this.amount = data.amount
    this.account = data.account
    this.isClosed = data.isClosed
    this.isExpired = data.isExpired
    this.secondaryCategory = new SecondaryCategory(data.secondaryCategory)
    this.spend = {
      approvedSpend: data.approvedSpend,
      invoicedSpend: data.invoicedSpend,
      pendingSpend: data.pendingSpend,
      purchasedSpend: data.purchasedSpend,
    }
    this.startDate = new Date(data.startDate)
    this.endDate = new Date(data.endDate)
  }
}
