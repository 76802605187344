import * as Factory from 'factory.ts'
import faker from 'faker'

import { PrimaryCategoryApiModelStub } from '@webapp/core/data-access/api-models/stubs/PrimaryCategoryApiModel.stub'
import { type LegacyBudgetOverviewApiModel } from '../LegacyBudgetOverviewApiModel'

export const LegacyBudgetOverviewApiModelStub =
  Factory.Sync.makeFactory<LegacyBudgetOverviewApiModel>({
    approved: Factory.each(() => faker.random.number().toString()),
    budget: Factory.each(() => faker.random.number().toString()),
    department: Factory.each(() => ({
      id: faker.random.number(),
      name: faker.random.word(),
      location: PrimaryCategoryApiModelStub.build(),
    })),
    endDate: Factory.each(() => faker.date.future().toISOString()),
    invoiced: Factory.each(() => faker.random.number().toString()),
    pending: Factory.each(() => faker.random.number().toString()),
    purchased: Factory.each(() => faker.random.number().toString()),
    startDate: Factory.each(() => faker.date.past().toISOString()),
  })
