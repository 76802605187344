import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const OrderRequestViewChunk = React.lazy(() =>
  import('../feature-sets/OrderRequestView').then(({ OrderRequestView }) => ({
    default: OrderRequestView,
  }))
)

export const OrderRequestPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.createOrderRequestPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.orderStatusPageTitle),
          link: url('OrderStatusPage', { status: 'pending' }),
        },
        {
          name: intl.formatMessage(messages.orderRequestPageTitle),
        },
      ]}
    >
      <OrderRequestViewChunk />
    </Page>
  )
}
