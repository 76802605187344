import { mapDepartment } from '@webapp/core/data-access/mappers/mapDepartment'
import { type LegacyBudgetOverviewConstructorData } from '../../entities/LegacyBudgetOverview'
import { type LegacyBudgetOverviewApiModel } from '../api-models/LegacyBudgetOverviewApiModel'

export const mapLegacyBudgetOverviewApiModel = (
  budgetOverview: LegacyBudgetOverviewApiModel
): LegacyBudgetOverviewConstructorData => ({
  amount: budgetOverview.budget,
  secondaryCategory: mapDepartment(budgetOverview.department),
  startDate: budgetOverview.startDate,
  endDate: budgetOverview.endDate,
  approvedSpend: budgetOverview.approved,
  invoicedSpend: budgetOverview.invoiced,
  pendingSpend: budgetOverview.pending,
  purchasedSpend: budgetOverview.purchased,
})
