import { type ApiError } from '@webapp/core/interfaces/api/ApiError'
import { RepoError } from '@webapp/infrastructure/interfaces/repositories/RepoError'
import { HttpError } from '@webapp/infrastructure/interfaces/services/IHttpClient'

const isHttpError = (error: unknown): error is HttpError<ApiError> => {
  return error instanceof HttpError
}

export const throwRepoErrorFromApiV3Error = (
  error: unknown,
  defaultMessage: string = 'Unexpected error',
  messageOverride: Record<string, string> = {}
): never => {
  if (!isHttpError(error)) throw error

  if (!error.response) {
    throw new RepoError({
      code: null,
      message: defaultMessage,
      status: error.status,
      response: error.response,
    })
  }

  const errResponse = error.response

  let nonFieldErrors = errResponse?.errors?.non_field_errors

  // if non_field_errors doesn't exist, take the first property in the errors object

  if (!nonFieldErrors) {
    for (const errKey in errResponse.errors) {
      if (errResponse.errors[errKey].message) {
        nonFieldErrors = errResponse?.errors[errKey]
        break
      }
    }
  }

  if (nonFieldErrors) {
    const errorMessage =
      messageOverride[nonFieldErrors.code] ||
      nonFieldErrors.message ||
      defaultMessage

    throw new RepoError({
      code: nonFieldErrors.code,
      message: errorMessage,
      status: error.status,
      response: error.response,
    })
  }

  throw new RepoError({
    code: null,
    message: defaultMessage,
    status: error.status,
    response: error.response,
  })
}
