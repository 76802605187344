import { defineMessages } from 'react-intl'

const scope = `procurify.components.SettingsView`

export default defineMessages({
  breadcrumbTitle: {
    id: `${scope}.breadcrumbTitle`,
    defaultMessage: 'Application settings',
  },
  setUpAndManageGroupTitle: {
    id: `${scope}.setUpAndManageGroupTitle`,
    defaultMessage: 'Set up & manage',
  },
  managePrimaryOrgCategoryLinkTitle: {
    id: `${scope}.managePrimaryOrgCategoryLinkTitle`,
    defaultMessage: 'Manage {singularPrimaryOrgCategoryLabel}',
  },
  managePrimaryOrgCategoryLinkDescription: {
    id: `${scope}.managePrimaryOrgCategoryLinkDescription`,
    defaultMessage:
      'Create & edit {singularPrimaryOrgCategoryLabel}/{singularSecondaryOrgCategoryLabel}',
  },
  manageUsersLinkTitle: {
    id: `${scope}.manageUsersLinkTitle`,
    defaultMessage: 'Manage users',
  },
  manageUsersLinkDescription: {
    id: `${scope}.manageUsersLinkDescription`,
    defaultMessage: 'Add, edit and invite users',
  },
  manageRolesAndPermissionsLinkTitle: {
    id: `${scope}.manageRolesAndPermissionsLinkTitle`,
    defaultMessage: 'Manage roles & permissions',
  },
  manageRolesAndPermissionsLinkDescription: {
    id: `${scope}.manageRolesAndPermissionsLinkDescription`,
    defaultMessage: 'Create & edit roles',
  },
  manageApprovalRoutingLinkTitle: {
    id: `${scope}.manageApprovalRoutingLinkTitle`,
    defaultMessage: 'Manage approval routing',
  },
  manageApprovalRoutingLinkDescription: {
    id: `${scope}.manageApprovalRoutingLinkDescription`,
    defaultMessage: 'Assign approvers to approval routings',
  },
  manageDateFormatLinkTitle: {
    id: `${scope}.manageDateFormatLinkTitle`,
    defaultMessage: 'Manage date format',
  },
  manageDateFormatLinkDescription: {
    id: `${scope}.manageDateFormatLinkDescription`,
    defaultMessage: 'Manage how date fields are displayed',
  },
  manageAccessGroupTitle: {
    id: `${scope}.manageAccessGroupTitle`,
    defaultMessage: 'Manage access',
  },
  approverViewAccessLinkTitle: {
    id: `${scope}.approverViewAccessLinkTitle`,
    defaultMessage: 'Approver view access',
  },
  approverViewAccessLinkDescription: {
    id: `${scope}.approverViewAccessLinkDescription`,
    defaultMessage:
      "Manage users' view access for other approvers' pending approvals",
  },
  reportsAccessLinkTitle: {
    id: `${scope}.reportsAccessLinkTitle`,
    defaultMessage: 'Reports access',
  },
  reportsAccessLinkDescription: {
    id: `${scope}.reportsAccessLinkDescription`,
    defaultMessage: 'Customize access to reports for users',
  },
  catalogItemAccessLinkTitle: {
    id: `${scope}.catalogItemAccessLinkTitle`,
    defaultMessage: 'Catalog item access',
  },
  catalogItemAccessLinkDescription: {
    id: `${scope}.catalogItemAccessLinkDescription`,
    defaultMessage: 'Customize access to catalog items',
  },
  ssoPreferencesLinkTitle: {
    id: `${scope}.ssoPreferencesLinkTitle`,
    defaultMessage: 'Security and authentication: SSO',
  },
  ssoPreferencesLinkDescription: {
    id: `${scope}.ssoPreferencesLinkDescription`,
    defaultMessage: 'Manage Single Sign-On preferences',
  },
  supportAccessLinkTitle: {
    id: `${scope}.supportAccessLinkTitle`,
    defaultMessage: 'Grant Procurify account access',
  },
  supportAccessLinkDescription: {
    id: `${scope}.supportAccessLinkDescription`,
    defaultMessage:
      'Allow the Procurify team access to your account for up to 30 days',
  },
  purchaseAgreementsAccessLinkTitle: {
    id: `${scope}.purchaseAgreementsAccessLinkTitle`,
    defaultMessage: 'Purchase agreement access',
  },
  purchaseAgreementsAccessLinkDescription: {
    id: `${scope}.purchaseAgreementsAccessLinkDescription`,
    defaultMessage: 'Allow requesters to self-manage approved agreements',
  },
  customizationsGroupTitle: {
    id: `${scope}.customizationsGroupTitle`,
    defaultMessage: 'Customizations',
  },
  // Remove when FT_7540_REORDER_NAVIGATION is removed
  customizeRequestForOrderLinkTitle: {
    id: `${scope}.customizeRequestForOrderLinkTitle`,
    defaultMessage: 'Customize request for order form',
  },
  customizeRequestForOrderLinkDescription: {
    id: `${scope}.customizeRequestForOrderLinkDescription`,
    defaultMessage: 'Create & edit request form custom fields',
  },
  // end block to remove
  customizeOrderFormLinkTitle: {
    id: `${scope}.customizeOrderFormLinkTitle`,
    defaultMessage: 'Customize order requisition form',
  },
  customizeOrderFormLinkDescription: {
    id: `${scope}.customizeOrderFormLinkDescription`,
    defaultMessage: 'Create & edit order request custom fields',
  },
  customizePurchaseOrderLinkTitle: {
    id: `${scope}.customizePurchaseOrderLinkTitle`,
    defaultMessage: 'Customize purchase order form',
  },
  customizePurchaseOrderLinkDescription: {
    id: `${scope}.customizePurchaseOrderLinkDescription`,
    defaultMessage: 'Create & edit purchase order custom fields & fine print',
  },
  // Remove when FT_7540_REORDER_NAVIGATION is removed
  customizeRequestForExpenseLinkTitle: {
    id: `${scope}.customizeRequestForExpenseLinkTitle`,
    defaultMessage: 'Customize request for expense form',
  },
  // end remove block
  customizeExpenseFormLinkTitle: {
    id: `${scope}.customizeExpenseFormLinkTitle`,
    defaultMessage: 'Customize expense form',
  },
  // Update key to bne customizeExpenseFormLinkDescription when FT_7540_REORDER_NAVIGATION is removed
  customizeRequestForExpenseLinkDescription: {
    id: `${scope}.customizeRequestForExpenseLinkDescription`,
    defaultMessage: 'Edit expense custom fields',
  },
  customizeUnitTypesLinkTitle: {
    id: `${scope}.customizeUnitTypesLinkTitle`,
    defaultMessage: 'Customize unit types',
  },
  customizeUnitTypesLinkDescription: {
    id: `${scope}.customizeUnitTypesLinkDescription`,
    defaultMessage: 'Create & edit unit types for order requests',
  },
  customizeBillLinkTitle: {
    id: `${scope}.customizeBillLinkTitle`,
    defaultMessage: 'Customize AP bill form',
  },
  customizeBillLinkDescription: {
    id: `${scope}.customizeBillLinkDescription`,
    defaultMessage: 'Configure matching rules, create & edit additional costs',
  },
  customizeSpendingCardLinkTitle: {
    id: `${scope}.customizeSpendingCardLinkTitle`,
    defaultMessage: 'Customize spending cards',
  },
  customizeSpendingCardLinkDescription: {
    id: `${scope}.customizeSpendingCardLinkDescription`,
    defaultMessage:
      'Create & edit custom fields for spending card transactions',
  },
  customizeOrganizationalCategoriesLinkTitle: {
    id: `${scope}.customizeOrganizationalCategoriesLinkTitle`,
    defaultMessage: 'Customize organizational categories',
  },
  customizeOrganizationalCategoriesLinkDescription: {
    id: `${scope}.customizeOrganizationalCategoriesLinkDescription`,
    defaultMessage: 'Edit your primary and secondary organizational categories',
  },
  vendorPerformanceLinkTitle: {
    id: `${scope}.vendorPerformanceLinkTitle`,
    defaultMessage: 'Customize vendor performance metrics',
  },
  vendorPerformanceLinkDescription: {
    id: `${scope}.vendorPerformanceLinkDescription`,
    defaultMessage: 'Manage vendor performance metrics & settings',
  },
  toolsGroupTitle: {
    id: `${scope}.toolsGroupTitle`,
    defaultMessage: 'Tools',
  },
  importDataLinkTitle: {
    id: `${scope}.importDataLinkTitle`,
    defaultMessage: 'Import data',
  },
  importDataLinkDescription: {
    id: `${scope}.importDataLinkDescription`,
    defaultMessage: 'Import data using CSV',
  },
  exportDataLinkTitle: {
    id: `${scope}.exportDataLinkTitle`,
    defaultMessage: 'Export data',
  },
  exportDataLinkDescription: {
    id: `${scope}.exportDataLinkDescription`,
    defaultMessage: 'Download data as CSV',
  },
  integrationsLinkTitle: {
    id: `${scope}.integrationsLinkTitle`,
    defaultMessage: 'Integrations',
  },
  integrationsLinkDescription: {
    id: `${scope}.integrationsLinkDescription`,
    defaultMessage: 'Integrate with 3rd party software',
  },
  punchOutLinkTitle: {
    id: `${scope}.punchOutLinkTitle`,
    defaultMessage: 'PunchOut suppliers',
  },
  punchOutLinkDescription: {
    id: `${scope}.punchOutLinkDescription`,
    defaultMessage:
      'Purchase from different suppliers on behalf of your business',
  },
  financeGroupTitle: {
    id: `${scope}.financeGroupTitle`,
    defaultMessage: 'Finance',
  },
  paymentsGroupTitle: {
    id: `${scope}.paymentsGroupTitles`,
    defaultMessage: 'Payments',
  },
  manageChartOfAccountsLinkTitle: {
    id: `${scope}.manageChartOfAccountsLinkTitle`,
    defaultMessage: 'Manage chart of accounts',
  },
  manageChartOfAccountsLinkDescription: {
    id: `${scope}.manageChartOfAccountsLinkDescription`,
    defaultMessage: 'Manage accounts & account codes',
  },
  manageBudgetCategoriesLinkTitle: {
    id: `${scope}.manageBudgetCategoriesLinkTitle`,
    defaultMessage: 'Manage budget categories',
  },
  manageBudgetCategoriesLinkDescription: {
    id: `${scope}.manageBudgetCategoriesLinkDescription`,
    defaultMessage: 'Manage how spend is tracked',
  },
  manageBudgetsLinkTitle: {
    id: `${scope}.manageBudgetsLinkTitle`,
    defaultMessage: 'Manage budgets',
  },
  manageBudgetsLinkDescription: {
    id: `${scope}.manageBudgetsLinkDescription`,
    defaultMessage: 'Manage budget dates & amounts',
  },
  accountCodeCorrectionLinkTitle: {
    id: `${scope}.accountCodeCorrectionLinkTitle`,
    defaultMessage: 'Account code correction',
  },
  accountCodeCorrectionLinkDescription: {
    id: `${scope}.accountCodeCorrectionLinkDescription`,
    defaultMessage: 'Adjust & allocate cost for order / expense items',
  },
  expenseAccountCodeCorrectionLinkDescription: {
    id: `${scope}.expenseAccountCodeCorrectionLinkDescription`,
    defaultMessage: 'Adjust & allocate cost for expense items',
  },
  manageFinancialAccountLinkTitle: {
    id: `${scope}.manageFinancialAccountLinkTitle`,
    defaultMessage: 'Manage financial account',
  },
  manageFinancialAccountsLinkTitle: {
    id: `${scope}.manageFinancialAccountsLinkTitle`,
    defaultMessage: 'Manage financial accounts',
  },
  manageFinancialAccountLinkDescription: {
    id: `${scope}.manageFinancialAccountLinkDescription`,
    defaultMessage: 'Set up and manage a financial account to pay bills',
  },
  manageFinancialAccountsLinkDescription: {
    id: `${scope}.manageFinancialAccountsLinkDescription`,
    defaultMessage: 'Manage balance to pay bills',
  },
  manageBankAndCreditCardAccountsLinkTitle: {
    id: `${scope}.manageBankAndCreditCardAccountsLinkTitle`,
    defaultMessage: 'Manage bank & credit card accounts',
  },
  manageBankAndCreditCardAccountsLinkDescription: {
    id: `${scope}.manageBankAndCreditCardAccountsLinkDescription`,
    defaultMessage: 'Manage external bank and credit card accounts',
  },
  manageCurrencyRatesLinkTitle: {
    id: `${scope}.manageCurrencyRatesLinkTitle`,
    defaultMessage: 'Manage currency rates',
  },
  manageCurrencyRatesLinkDescription: {
    id: `${scope}.manageCurrencyRatesLinkDescription`,
    defaultMessage: 'Manage currency rates for conversion',
  },
  manageTaxTypesLinkTitle: {
    id: `${scope}.manageTaxTypesLinkTitle`,
    defaultMessage: 'Manage tax types',
  },
  manageTaxTypesLinkDescription: {
    id: `${scope}.manageTaxTypesLinkDescription`,
    defaultMessage: 'Define taxes for expenses & AP',
  },
  expenseManageTaxTypesLinkDescription: {
    id: `${scope}.expenseManageTaxTypesLinkDescription`,
    defaultMessage: 'Define taxes for expenses',
  },
  closingMonthsLinkTitle: {
    id: `${scope}.closingMonthsLinkTitle`,
    defaultMessage: 'Closing months',
  },
  closingMonthsLinkDescription: {
    id: `${scope}.closingMonthsLinkDescription`,
    defaultMessage: 'Close or reopen months for AP',
  },
  expenseClosingMonthsLinkDescription: {
    id: `${scope}.expenseClosingMonthsLinkDescription`,
    defaultMessage: 'Close or reopen months for expense bills',
  },
  contractSettingsLinkTitle: {
    id: `${scope}.contractSettingsLinkTitle`,
    defaultMessage: 'Customize contracts',
  },
  contractSettingsLinkDescription: {
    id: `${scope}.contractSettingsLinkDescription`,
    defaultMessage: 'Edit your contract upload settings',
  },
  emptyStateHeader: {
    id: `${scope}.emptyStateHeader`,
    defaultMessage: "There's nothing here",
  },
  emptyStateContent: {
    id: `${scope}.emptyStateContent`,
    defaultMessage: 'No settings available',
  },
  betaTag: {
    id: `${scope}.betaTag`,
    defaultMessage: 'Beta',
  },
  newTag: {
    id: `${scope}.newTag`,
    defaultMessage: 'New',
  },
  MFAPreferencesLinkTitle: {
    id: `${scope}.MFAPreferencesLinkTitle`,
    defaultMessage: 'Security and authentication: MFA',
  },
  MFAPreferencesLinkDescription: {
    id: `${scope}.MFAPreferencesLinkDescription`,
    defaultMessage: 'Manage Multi-Factor Authentication preferences',
  },
  manageBankAccountsLinkTitle: {
    id: `${scope}.manageBankAccountsLinkTitle`,
    defaultMessage: 'Manage linked bank accounts',
  },
  manageBankAccountsLinkDescription: {
    id: `${scope}.manageBankAccountsLinkDescription`,
    defaultMessage: 'Manage linked bank accounts for payments',
  },
})
