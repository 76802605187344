import { defineMessages } from 'react-intl'

const scope = `procurify.order.pages`

export default defineMessages({
  orderDetailsPageTitle: {
    id: `${scope}.orderDetailsPageTitle`,
    defaultMessage: 'Order Details',
  },
  orderRequestPageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Create order request',
  },
  orderStatusPageTitle: {
    id: `${scope}.orderStatusPageTitle`,
    defaultMessage: 'Order status',
  },
  createOrderRequestPageTitle: {
    id: `${scope}.createOrderRequestPageTitle`,
    defaultMessage: 'Create order request',
  },
})
