import * as Factory from 'factory.ts'
import faker from 'faker'

import { SecondaryCategoryConstructorFactory } from '@webapp/core/entities/internal/SecondaryCategory'
import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import { LegacyBudget, type LegacyBudgetConstructorData } from './LegacyBudget'

export const LegacyBudgetConstructorFactory =
  Factory.Sync.makeFactory<LegacyBudgetConstructorData>({
    id: Factory.each(() => faker.random.number()),
    account: Factory.each(() => ({
      accountCode: {
        id: faker.random.number(),
        code: faker.finance.account(),
        description: faker.finance.accountName(),
      },
      id: faker.random.number(),
    })),
    isClosed: Factory.each(() => faker.random.boolean()),
    isExpired: Factory.each(() => faker.random.boolean()),
    amount: Factory.each(() => faker.random.number().toString()),
    secondaryCategory: Factory.each(() =>
      SecondaryCategoryConstructorFactory.build()
    ),
    startDate: Factory.each(() => faker.date.recent().toISOString()),
    endDate: Factory.each(() => faker.date.recent().toISOString()),
    approvedSpend: Factory.each(() => faker.random.number().toString()),
    invoicedSpend: Factory.each(() => faker.random.number().toString()),
    pendingSpend: Factory.each(() => faker.random.number().toString()),
    purchasedSpend: Factory.each(() => faker.random.number().toString()),
  })

export const LegacyBudgetFactory = EntityFactory(
  LegacyBudget,
  LegacyBudgetConstructorFactory
)
